<template>
  <template v-for="alert in messages" :key="alert.id">
    <!-- // Currently passed as props - should be changed to v-bind -->
    <UiPopup :alert="alert" @close="() => alert.destroy?.()" />
  </template>
</template>

<script setup lang="ts">
const { messages, clear } = usePopup()

onBeforeUnmount(() => clear())
</script>

<script setup lang="ts">
import type { HTMLAttributes } from "vue"

const props = defineProps<{ class?: HTMLAttributes["class"] }>()
</script>

<template>
  <div :class="cn('mt-6 flex border-t pt-4 md:flex-row md:justify-end md:gap-x-2', props.class)">
    <slot />
  </div>
</template>
